import { createTheme } from '@mui/material/styles';
import decidaloMaterialDefaultColors from '../decidalo-theme-new-design/decidalo-theme-palette-new-design.module.less';

const decidaloMaterialDefaultsNewDesign = createTheme({
    typography: {
        fontFamily: [
            '-apple-system',
            ' BlinkMacSystemFont',
            'Open Sans',
            'Segoe UI',
            'Roboto',
            'Oxygen',
            'Ubuntu',
            'Fira Sans',
            'Droid Sans',
            'Helvetica Neue',
            ' sans-serif',
        ].join(','),
        // TODO: Discuss about h1 size and usage
        // h1: {
        //     color: decidaloMaterialDefaultColors.textColor,
        //     fontSize: '2rem',
        //     fontWeight: '700',
        //     lineHeight: '2.5rem',
        // },
        h1: {
            color: decidaloMaterialDefaultColors.greysGrey900,
            fontSize: '1.5rem',
            fontWeight: '700',
            lineHeight: '2rem',
        },
        h2: {
            color: decidaloMaterialDefaultColors.textColor,
            fontSize: '1.25rem',
            fontWeight: '700',
            lineHeight: '1.75rem',
        },
        h3: {
            color: decidaloMaterialDefaultColors.greysGrey900,
            fontSize: '1.0625rem',
            fontWeight: '700',
            lineHeight: '1.5rem',
        },
        gridHeading: {
            color: decidaloMaterialDefaultColors.basicBlack,
            fontSize: '0.875rem',
            fontWeight: '700',
            lineHeight: '1.25rem',
        },
        body1: {
            color: decidaloMaterialDefaultColors.basicBlack,
            fontSize: '0.875rem',
            fontWeight: '600',
            lineHeight: '1.25rem',
        },
        body2: {
            color: decidaloMaterialDefaultColors.textColor,
            fontSize: '0.875rem',
            fontWeight: '400',
            lineHeight: '1.125rem',
        },
        subtitle1: {
            color: decidaloMaterialDefaultColors.textColor,
            fontSize: '0.8125rem',
            fontWeight: '400',
            lineHeight: '1.125rem',
        },
        infoText: {
            color: decidaloMaterialDefaultColors.grayTextColor,
            fontStyle: 'italic',
            fontSize: '0.875rem',
            fontWeight: '400',
        },
        small: {
            color: decidaloMaterialDefaultColors.greysGrey600,
            fontSize: '0.75rem',
            fontWeight: '400',
            lineHeight: '1rem',
        },
        mainNav: {
            color: decidaloMaterialDefaultColors.textColor,
            fontSize: '0.875rem',
            fontWeight: '400',
            lineHeight: '1.5rem',
        },

        /*
        https://decidalo.atlassian.net/browse/DV3-507

        Apparently, some MUI components rely on some typographies to be always defined.
        Despite their documentation also claims that typographies should also be disabled by setting properties to undefined.
        (https://mui.com/material-ui/customization/typography/#adding-amp-disabling-variants)

        Commenting out the following lines is a work-around for the fact the MUI components need or may need defined typographies.

        Type definitions further below should still prevent these typographies to be used in custom solutions (type TypographyVariants and following).
        */
        /*
        h5: undefined, // Disabled. If needed, it requries UI board.
        h6: undefined, // Disabled. If needed, it requries UI board.
        button: undefined, // Disabled. If needed, it requries UI board.
        caption: undefined, // Disabled. If needed, it requries UI board.
        subtitle1: undefined, // Disabled. If needed, it requries UI board.
        infoText: undefined, // Disabled. If needed, it requries UI board.
        body12: undefined, // Disabled. If needed, it requries UI board.
        overline: undefined, // Disabled. If needed, it requries UI board.
        */
    },
    components: {
        MuiCssBaseline: {
            styleOverrides: {
                body: {
                    backgroundColor:
                        decidaloMaterialDefaultColors.basicBackgroundColor,
                },
            },
        },
        MuiInput: {
            defaultProps: {
                size: 'small',
                fullWidth: true,
            },
        },
        MuiInputBase: {
            styleOverrides: {
                root: {
                    lineHeight: '1.25rem',
                    alignSelf: 'stretch',
                    backgroundColor: decidaloMaterialDefaultColors.basicWhite,
                },
            },
        },
        MuiFormLabel: {
            styleOverrides: {
                root: {
                    color: decidaloMaterialDefaultColors.greysGrey500,
                    fontWeight: 400,
                },
            },
        },
        MuiTablePagination: {
            styleOverrides: {
                root: {
                    flexGrow: 1,
                },
                spacer: {
                    flex: 0,
                },
                toolbar: {
                    width: '95%',
                    alignItems: 'center',
                },
                select: {
                    display: 'flex',
                    alignItems: 'center',
                    fontSize: '0.875rem',
                    marginLeft: 0,
                    marginBottom: '-0.25rem',
                },
                selectLabel: {
                    marginRight: '-0.5rem',
                },
                displayedRows: {
                    flexGrow: 1,
                    display: 'flex',
                    justifyContent: 'center',
                },
            },
        },
        MuiTextField: {
            defaultProps: {
                variant: 'outlined',
                size: 'small',
                fullWidth: true,
            },
            styleOverrides: {
                root: {
                    '& .MuiInputBase-input': {
                        color: decidaloMaterialDefaultColors.greysGrey600,
                    },
                    '& .MuiOutlinedInput-notchedOutline': {
                        borderColor: decidaloMaterialDefaultColors.greysGrey400,
                    },
                    '&:hover .MuiOutlinedInput-notchedOutline': {
                        borderColor: decidaloMaterialDefaultColors.primaryColor,
                    },
                    '&:active .MuiOutlinedInput-notchedOutline': {
                        borderColor: decidaloMaterialDefaultColors.primaryColor,
                    },
                },
            },
        },
        MuiFilledInput: {
            styleOverrides: {
                input: {
                    backgroundColor:
                        decidaloMaterialDefaultColors.inputBackgroundColor,
                    width: '100%',
                },
            },
        },
        MuiSelect: {
            styleOverrides: {
                select: {
                    icon: {
                        fill: decidaloMaterialDefaultColors.greysGrey500,
                        width: '1.5rem',
                        height: '1.5rem',
                    },
                },
            },
            defaultProps: {
                variant: 'filled',
                size: 'small',
            },
        },
        MuiFormControlLabel: {
            styleOverrides: {
                root: {
                    fontSize: '14px',
                    margin: '0px',
                },
            },
        },
        MuiLink: {
            styleOverrides: {
                root: {
                    cursor: 'pointer',
                },
            },
            defaultProps: {
                underline: 'hover',
                color: decidaloMaterialDefaultColors.linkColor,
            },
        },
        MuiSnackbar: {
            defaultProps: {
                autoHideDuration: 5000,
            },
        },
        MuiFormHelperText: {
            styleOverrides: {
                root: {
                    marginLeft: '14px',
                    marginRight: '14px',
                },
            },
        },
        MuiStepIcon: {
            styleOverrides: {
                root: {
                    fontSize: '2rem',
                },
                text: {
                    fontSize: '0.75rem',
                },
            },
        },
        MuiStepLabel: {
            styleOverrides: {
                label: {
                    fontSize: 'inherit',
                },
            },
        },
        MuiSwitch: {
            styleOverrides: {
                sizeSmall: {
                    width: 23,
                    height: 15,

                    '& .MuiSwitch-thumb': {
                        boxSizing: 'border-box',
                        width: 11,
                        height: 11,
                    },
                    '& .MuiSwitch-switchBase': {
                        '&.Mui-checked': {
                            transform: 'translateX(7px)',
                            color: decidaloMaterialDefaultColors.basicWhite,
                            '& + .MuiSwitch-track': {
                                backgroundColor:
                                    decidaloMaterialDefaultColors.primaryColor,
                                opacity: 1,
                                border: 0,
                            },
                            '&.Mui-disabled + .MuiSwitch-track': {
                                opacity: 0.5,
                            },
                        },
                    },
                },

                sizeMedium: {
                    width: 39,
                    height: 23,
                    borderRadius: 16,

                    '& .MuiSwitch-thumb': {
                        boxSizing: 'border-box',
                        width: 18,
                        height: 18,
                    },
                    '& .MuiSwitch-switchBase': {
                        '&.Mui-checked': {
                            transform: 'translateX(16px)',
                            color: decidaloMaterialDefaultColors.basicWhite,
                            '& + .MuiSwitch-track': {
                                backgroundColor:
                                    decidaloMaterialDefaultColors.primaryColor,
                                opacity: 1,
                                border: 0,
                            },
                            '&.Mui-disabled + .MuiSwitch-track': {
                                opacity: 0.3,
                            },
                        },
                    },
                },
                root: {
                    padding: 0,
                    '& .MuiSwitch-switchBase': {
                        padding: 0,
                        margin: 2.5,
                        transitionDuration: '300ms',

                        '&.Mui-focusVisible .MuiSwitch-thumb': {
                            color: decidaloMaterialDefaultColors.primaryColor,
                            border:
                                '6px solid' +
                                decidaloMaterialDefaultColors.basicWhite,
                        },
                        '&.Mui-disabled .MuiSwitch-thumb': {
                            color: decidaloMaterialDefaultColors.basicWhite,
                        },
                        '&.Mui-disabled + .MuiSwitch-track': {
                            opacity: 0.7,
                        },
                    },
                },
            },
        },
        MuiTab: {
            styleOverrides: {
                textColorPrimary: decidaloMaterialDefaultColors.primaryColor,
                textColorSecondary: decidaloMaterialDefaultColors.greysGrey600,
                root: {
                    textTransform: 'none',
                },
            },
        },
        //  MuiStep
        MuiStepConnector: {
            styleOverrides: {
                root: {
                    top: '1rem',
                    left: 'calc(-50% + 24px)',
                    right: 'calc(50% + 24px)',
                },
            },
        },
        MuiIconButton: {
            defaultProps: {
                color: 'primary',
            },
            styleOverrides: {
                root: ({ ownerState }) => ({
                    ...(ownerState.color === 'primary' &&
                        !ownerState.disabled && {
                            '& > svg': {
                                color: decidaloMaterialDefaultColors.primaryColor,
                            },
                            '& > span > span >span.MuiTouchRipple-child': {
                                color: decidaloMaterialDefaultColors.toggledHoverBackgroundColor,
                            },
                        }),
                    ...(ownerState.color === 'error' &&
                        !ownerState.disabled && {
                            '& > svg': {
                                color: decidaloMaterialDefaultColors.error,
                            },
                            '& > span > span >span.MuiTouchRipple-child': {
                                color: decidaloMaterialDefaultColors.error,
                            },
                        }),
                    '&:hover': {
                        backgroundColor: 'rgba(0, 91, 141, 0.10)',
                    },

                    textTransform: 'none',
                    width: 'fit-content',
                    color: 'inherit',
                }),
            },
        },
        MuiToggleButton: {
            defaultProps: {
                color: 'primary',
            },
            styleOverrides: {
                root: ({ ownerState }) => ({
                    '& > svg': {
                        height: '1rem',
                    },
                    borderLeft: '1px solid red',
                    borderRadius: '4px',
                    gap: '6px',
                    textTransform: 'none',
                    ...(ownerState.color === 'primary' && {
                        color: decidaloMaterialDefaultColors.primaryColor,
                        borderColor: decidaloMaterialDefaultColors.primaryColor,
                        '&.Mui-selected': {
                            backgroundColor:
                                decidaloMaterialDefaultColors.toggleBackgroundColor,
                        },
                        '&.Mui-selected:hover': {
                            backgroundColor:
                                decidaloMaterialDefaultColors.toggleHoverBackgroundColor,
                        },
                        '&:hover': {
                            backgroundColor:
                                decidaloMaterialDefaultColors.hoverBackgroundColor,
                        },
                    }),
                }),
            },
        },
        MuiToggleButtonGroup: {
            defaultProps: {
                color: 'primary',
            },
            styleOverrides: {
                root: {
                    '& .MuiToggleButtonGroup-grouped:not(:first-of-type)': {
                        border: '1px solid',
                        borderRadius: '4px',
                    },
                    '& .MuiToggleButtonGroup-grouped:not(:last-of-type)': {
                        border: '1px solid',
                        borderRadius: '4px',
                    },
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    fontSize: '0.875',
                    fontWeight: '600',
                    lineHeight: '180%',
                    textTransform: 'none',
                    //this sets the height of the icon if its used
                    '& > span > svg': {
                        height: '1rem',
                    },
                },
            },
            defaultProps: {
                size: 'small',
                variant: 'primaryAction',
            },
            variants: [
                {
                    props: { variant: 'primaryAction' },
                    style: {
                        borderRadius: '0.25rem',
                        backgroundColor:
                            decidaloMaterialDefaultColors.primaryColor,
                        color: decidaloMaterialDefaultColors.basicWhite,
                        display: 'inline-flex',
                        height: '2.5rem',
                        padding: '0rem 1.5rem',
                        justifyContent: 'center',
                        alignItems: 'center',
                        gap: '0.25rem',
                        flexShrink: 0,
                        '&:hover': {
                            backgroundColor: '#004C75',
                            color: decidaloMaterialDefaultColors.basicWhite,
                        },
                        '&:focus': {
                            background: '#003B5C',
                            color: decidaloMaterialDefaultColors.basicWhite,
                        },
                        '&:disabled': {
                            backgroundColor: '#D9D9D9',
                            color: decidaloMaterialDefaultColors.basicWhite,
                        },
                    },
                },
                {
                    props: { variant: 'secondaryAction' },
                    style: {
                        borderRadius: '0.25rem',
                        border: `1px solid ${decidaloMaterialDefaultColors.primaryColor}`,
                        backgroundColor:
                            decidaloMaterialDefaultColors.basicWhite,
                        color: decidaloMaterialDefaultColors.primaryColor,
                        display: 'inline-flex',
                        height: '2.5rem',
                        padding: '0rem 1.5rem',
                        justifyContent: 'center',
                        alignItems: 'center',
                        gap: '0.25rem',
                        flexShrink: 0,
                        '&:hover': {
                            border: `1px solid ${decidaloMaterialDefaultColors.primaryColor}`,
                            backgroundColor: 'rgba(0, 91, 141, 0.10)',
                            color: decidaloMaterialDefaultColors.primaryColor,
                        },
                        '&:focus': {
                            border: `1px solid ${decidaloMaterialDefaultColors.primaryColor}`,
                            backgroundColor:
                                decidaloMaterialDefaultColors.basicWhite,
                            color: decidaloMaterialDefaultColors.primaryColor,
                        },
                        '& > span > svg': {
                            color: decidaloMaterialDefaultColors.primaryColor,
                        },
                        '&:disabled': {
                            color: '#D9D9D9',
                            backgroundColor:
                                decidaloMaterialDefaultColors.basicWhite,
                            border: '1px solid #D9D9D9',
                            '& > span > svg': {
                                color: '#D9D9D9',
                            },
                            '& > div': {
                                color: '#D9D9D9',
                            },
                        },
                    },
                },
                {
                    props: { variant: 'action' },
                    style: {
                        borderRadius: '0.25rem',
                        backgroundColor:
                            decidaloMaterialDefaultColors.basicWhite,
                        color: decidaloMaterialDefaultColors.primaryColor,
                        display: 'inline-flex',
                        height: '2.5rem',
                        padding: '0rem 1.5rem',
                        justifyContent: 'center',
                        alignItems: 'center',
                        gap: '0.25rem',
                        flexShrink: 0,
                        '&:hover': {
                            backgroundColor: 'rgba(0, 91, 141, 0.10)',
                        },
                        '&:focus': {
                            border: `1px solid ${decidaloMaterialDefaultColors.primaryColor}`,
                            backgroundColor:
                                decidaloMaterialDefaultColors.basicWhite,
                            color: decidaloMaterialDefaultColors.primaryColor,
                        },
                        '&:disabled': {
                            backgroundColor:
                                decidaloMaterialDefaultColors.basicWhite,
                            color: '#D9D9D9',
                        },
                    },
                },
                {
                    props: { color: 'error' },
                    style: {
                        color: decidaloMaterialDefaultColors.error,
                        '&:hover': {
                            backgroundColor:
                                decidaloMaterialDefaultColors.basicBackgroundColor,
                        },
                    },
                },
            ],
        },
        MuiChip: {
            styleOverrides: {
                label: {
                    fontSize: '13px',
                    fontWeight: 400,
                    lineHeight: '16px',
                    padding: '0px',
                    alignSelf: 'center',
                },
                root: {
                    borderRadius: '32px',
                    padding: '4px 10px',
                    display: 'flex-start',
                    alignItems: 'flex-start',
                    minWidth: '54px',
                    minHeight: '21px',
                    width: 'fit-content',
                    height: 'fit-content',
                },
            },
        },
        MuiListItem: {
            styleOverrides: {
                root: {
                    '&:hover': {
                        backgroundColor:
                            decidaloMaterialDefaultColors.hoverBackgroundColor,
                    },
                    '&:disabled': {
                        color: decidaloMaterialDefaultColors.disabledTextColor,
                        opacity: '0.56',
                    },
                    '&:not(.Mui-disabled) svg': {
                        color: decidaloMaterialDefaultColors.primaryColor,
                    },
                    color: decidaloMaterialDefaultColors.basicBlack,
                },
            },
        },
        MuiListItemIcon: {
            styleOverrides: {
                root: {
                    color: decidaloMaterialDefaultColors.primaryColor,
                },
            },
        },
        MuiMenu: {
            styleOverrides: {
                root: ({ _theme }) => ({
                    '& .MuiMenu-paper': {
                        borderRadius: '8px',
                        border:
                            '1px solid ' +
                            decidaloMaterialDefaultColors.listBorder,
                        boxShadow:
                            ' 0px 2px 6px 0px ' +
                            decidaloMaterialDefaultColors.cardBoxShadow,
                    },
                }),
            },
        },
        MuiMenuItem: {
            styleOverrides: {
                root: {
                    '&:hover': {
                        backgroundColor:
                            decidaloMaterialDefaultColors.hoverBackgroundColor,
                    },
                    '&:disabled': {
                        color: decidaloMaterialDefaultColors.disabledTextColor,
                        opacity: '1',
                    },
                    '&:not(.Mui-disabled) svg': {
                        color: decidaloMaterialDefaultColors.primaryColor,
                    },

                    padding: '8px',
                    fontSize: 'inherit',
                    color: decidaloMaterialDefaultColors.basicBlack,
                },
            },
        },
        MuiCheckbox: {
            styleOverrides: {
                root: {
                    display: 'flex',
                    color: decidaloMaterialDefaultColors.greysGrey500,
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexShrink: '0',
                    width: '1.5rem',
                    height: '1.5rem',
                    '&.Mui-checked': {
                        color: decidaloMaterialDefaultColors.primaryColor,
                    },
                },
            },
        },
        MuiRadio: {
            styleOverrides: {
                root: {
                    color: decidaloMaterialDefaultColors.greysGrey500,
                    flexShrink: '0',
                    width: '1.5rem',
                    height: '1.5rem',
                    '&.Mui-checked': {
                        color: decidaloMaterialDefaultColors.primaryColor,
                    },
                },
            },
        },
        MuiDialog: {
            defaultProps: {
                fullWidth: true,
                maxWidth: 'sm',
            },
        },
        MuiDialogTitle: {
            styleOverrides: {
                root: {
                    textAlign: 'start',
                },
            },
        },
        MuiPaper: {
            variants: [
                {
                    props: { variant: 'tile' },
                    style: ({ theme }) => ({
                        borderRadius: '4px',
                        // Generated box shadow style from: Mui Paper variant='elevation' elevation={1}
                        boxShadow: theme.shadows[1], // '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
                    }),
                },
                {
                    //TODO: Review variant in DV3-488
                    props: { variant: 'container' },
                    style: ({ theme }) => ({
                        borderRadius: '4px',
                        boxShadow: theme.shadows[1], // '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
                        padding: '20px',
                    }),
                },
            ],
        },
        MuiLinearProgress: {
            styleOverrides: {
                root: {
                    height: '1rem',
                    borderRadius: '5px',
                },
                bar: {
                    borderRadius: '5px',
                    backgroundColor: decidaloMaterialDefaultColors.primaryColor,
                },
                colorPrimary: {
                    backgroundColor:
                        decidaloMaterialDefaultColors.progressBarBackgroundColor,
                },
            },
        },
        MuiAccordion: {
            styleOverrides: {
                root: ({ theme, ownerState }) => ({
                    marginTop: ownerState.disableGutters ? '0px' : '15px',
                    paddingTop: '10px',
                    paddingBottom: '10px',
                    borderRadius: ownerState.square ? '0px' : '4px',
                    boxShadow: theme.shadows[1],
                    border:
                        '1px solid ' +
                        decidaloMaterialDefaultColors.sectionBorderColor,
                }),
            },
        },
        MuiAccordionSummary: {
            styleOverrides: {
                root: {
                    flexDirection: 'row-reverse',
                    minHeight: '65px',
                },
                content: {
                    display: 'block',
                },
                expandIconWrapper: {
                    marginRight: '10px',

                    '&.Mui-expanded': {
                        transform: 'rotate(90deg)',
                    },
                },
            },
        },
        MuiAccordionDetails: {
            styleOverrides: {
                root: {
                    '> *': {
                        marginBottom: '2px',
                    },
                },
            },
        },
        MuiAlert: {
            styleOverrides: {
                root: ({ ownerState, theme }) => ({
                    ...(ownerState.severity === 'error' && {
                        outline: '3px solid',
                        outlineColor: theme.palette.error.light,
                        borderRadius: '5px',
                    }),
                }),
            },
        },
        MuiCard: {
            styleOverrides: {
                root: {
                    flexDirection: 'column',
                    borderRadius: '0.5rem',
                    background: decidaloMaterialDefaultColors.basicWhite,
                    border:
                        '1px solid' +
                        decidaloMaterialDefaultColors.greysGrey200,
                    boxShadow:
                        '0px 1px 2px 0px ' +
                        decidaloMaterialDefaultColors.boxShadow,
                    position: 'relative',
                    '&:hover': {
                        backgroundColor:
                            decidaloMaterialDefaultColors.basicHover,
                        cursor: 'pointer',
                    },
                },
            },
        },
        MuiImageListItemBar: {
            styleOverrides: {
                root: {
                    background: decidaloMaterialDefaultColors.overlayColor,
                },
            },
        },
        MuiAutocomplete: {
            styleOverrides: {
                inputRoot: ({ ownerState }) => ({
                    '&[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input':
                        {
                            padding: '8.5px 14px',
                            paddingRight: '0',
                        },
                    ...(ownerState.size === 'small' && {
                        '&[class*="MuiOutlinedInput-root"]': {
                            height: '26px',
                            padding: 0,
                            paddingRight: '14px',
                            minWidth: '200px',
                            alignContent: 'center',
                        },
                    }),
                    ...(ownerState.size === 'medium' && {
                        '&[class*="MuiOutlinedInput-root"]': {
                            padding: 0,
                            paddingRight: '14px',
                            minWidth: '200px',
                            alignContent: 'center',
                        },
                    }),
                }),
            },
        },
        MuiTableCell: {
            variants: [
                {
                    props: { variant: 'text' },
                    style: ({ theme }) => ({
                        width: '240px',
                    }),
                },
            ],
        },

        MuiDrawer: {
            styleOverrides: {
                paper: {
                    boxShadow: '-2px 0px 10px 0px rgba(0, 0, 0, 0.2)',
                    minWidth: '45vw',
                    maxWidth: '45vw',
                    padding: '8px 20px',
                    boxSizing: 'border-box',
                },
            },
        },
    },

    /**
     *These all are material ui default values, we just set theme here explicitly in order to be aware of them
     *and not to change them. Before you think you need to change any of these values, think if you are adjusting everything to conform
     *one special use case and if you can somehow avoid it or do it in another way.
     *Example of adjusting may be found in @see {DecidaloEntitySearchDialog}, where we open drawer from dialog (which is not basic
     *and expected ui flow). Because of that we have added a prop(hasDrawer) if dialog has drawer inside it(or more precisely drawer may be opened
     * inside it). In this case we adjust zIndex inside @see {DecidaloDialog} component to be value of drawer component - 1.
     */

    zIndex: {
        mobileStepper: 1000,
        fab: 1050,
        speedDial: 1050,
        appBar: 1100,
        drawer: 1200,
        modal: 1300,
        snackbar: 1400,
        tooltip: 1500,
    },
});

declare module '@mui/material/Button' {
    interface ButtonPropsVariantOverrides {
        primaryAction: true;
        secondaryAction: true;
        action: true;
        outlined: false;
        contained: false;
    }
}

declare module '@mui/material/ToggleButton' {
    interface ToggleButtonPropsColorOverrides {
        secondary: false;
        error: false;
        info: false;
        success: false;
        warning: false;
        standard: false;
    }
}

declare module '@mui/material/ToggleButtonGroup' {
    interface ToggleButtonGroupPropsColorOverrides {
        secondary: false;
        error: false;
        info: false;
        success: false;
        warning: false;
        standard: false;
    }
}

/*
 * For adjusting typography types, a few redundant type redefinitions are necessary.
 * https://mui.com/material-ui/customization/typography/#adding-amp-disabling-variants
 */
declare module '@mui/material/styles' {
    interface TypographyVariants {
        h1: React.CSSProperties;
        h2: React.CSSProperties;
        h3: React.CSSProperties;
        gridHeading: React.CSSProperties;
        body1: React.CSSProperties;
        body2: React.CSSProperties;
        subtitle1: React.CSSProperties;
        infoText: React.CSSProperties;
        small: React.CSSProperties;
        mainNav: React.CSSProperties;
    }

    interface TypographyVariantsOptions {
        h1?: React.CSSProperties;
        h2?: React.CSSProperties;
        h3?: React.CSSProperties;
        gridHeading: React.CSSProperties;
        body1?: React.CSSProperties;
        body2?: React.CSSProperties;
        subtitle1: React.CSSProperties;
        infoText?: React.CSSProperties;
        small?: React.CSSProperties;
        mainNav?: React.CSSProperties;
    }
}

declare module '@mui/material/Typography' {
    interface TypographyPropsVariantOverrides {
        h1: true;
        h2: true;
        h3: true;
        gridHeading: true;
        body1: true;
        small: true;
        mainNav: true;
        body2: true;
        subtitle1: true;
        infoText: true;

        h4: false; // Disabled. If needed, it requries UI board.
        h5: false; // Disabled. If needed, it requries UI board.
        h6: false; // Disabled. If needed, it requries UI board.
        button: false; // Disabled. If needed, it requries UI board.
        caption: false; // Disabled. If needed, it requries UI board.
        inherit: false; // Disabled. If needed, it requries UI board.

        overline: false; // Disabled. If needed, it requries UI board.
    }
}

declare module '@mui/material/Paper' {
    interface PaperPropsVariantOverrides {
        tile: true;
        container: true;
        elevation: false; // Disabled. If needed, it requries UI board.
    }
}

declare module '@mui/material/IconButton' {
    interface IconButtonPropsColorOverrides {
        default: false;
        secondary: false;
        info: false;
        success: false;
        warning: false;
    }
}

declare module '@mui/material/TableCell' {
    interface TableCellPropsVariantOverrides {
        text: true;
    }
}

export { decidaloMaterialDefaultsNewDesign };
