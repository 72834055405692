import { createContext, useState } from 'react';
import { Dictionary, IconVariant } from '@decidalo-frontend/models';

type IconContextType = [
    (
        icon: string,
        variant: IconVariant,
        setSvgData: (data: string) => void,
        setError: (error: string) => void,
    ) => Promise<void>,
];

export const IconContext = createContext<IconContextType>([
    () => Promise.resolve(),
]);

export const IconContextProvider = (props: { children: JSX.Element }) => {
    const [iconDictionary, setIconDictionary] = useState<Dictionary<string>>(
        {},
    );

    // The purpose of this function is to do the fetches for icons one after the other (and not parallel)
    // to avoid loading the same icons multiple times in parallel.
    // Got the general idea from https://stackoverflow.com/a/53540586
    // The function has to be put into a state to recognize other state changes.
    const [fetchIcon] = useState<
        (
            icon: string,
            variant: IconVariant,
            setSvgData: (data: string) => void,
            setError: (error: string) => void,
        ) => Promise<void>
    >(() => {
        let pending = Promise.resolve();

        // we have to use a temporary variable for the dict because the iconDictionary will not update
        // while the function is running, which it does while new fetches are attached.
        const newIconDict = { ...iconDictionary };

        // This waits for currently pending fetches and executes them in order
        const addTask = async (
            icon: string,
            variant: IconVariant,
            setSvgData: (data: string) => void,
            setError: (error: string) => void,
        ) => {
            try {
                await pending;
            } finally {
                // The actual asynchronous logic is wrapped in the finally to wait for pending asynchronous code.

                // function definition of what is actually happening asynchronously and what went in parallel before.
                const importIcon = async () => {
                    try {
                        // TODO: After first deployment, check if these are cached
                        const namedImport = await fetch(
                            `./assets/icons/${variant}/${icon}.svg`,
                        );
                        if (namedImport && namedImport.status < 400) {
                            // execute the callback and save the fetch-result in the dictionary
                            const iconText = await namedImport.text();
                            setSvgData(iconText);
                            newIconDict[variant + '_' + icon] = iconText;
                        } else {
                            setError('Icon could not be loaded.');
                        }
                    } catch (e) {
                        //TODO: Error handling
                        console.log(e);
                    }
                };

                // The await in this block is where the magic happens. Because of that, the next request will have to wait.
                if (newIconDict[variant + '_' + icon]) {
                    setSvgData(newIconDict[variant + '_' + icon]);
                } else {
                    await importIcon();
                }
                setIconDictionary(newIconDict);
            }
        };

        // The currently pending request is set and returned. This chains all pending requests.
        return (
            icon: string,
            variant: IconVariant,
            setSvgData: (data: string) => void,
            setError: (error: string) => void,
        ) => {
            pending = addTask(icon, variant, setSvgData, setError);
            return pending;
        };
    });

    // TODO: Remove the commented out code when it is assured that the fetches above are cached

    // const [finishedLoading, setFinishedLoading] = useState<boolean>(false);

    // useEffect(
    //     () => {
    //         const localStorageDict = localStorage.getItem("iconDictionary");
    //         if(localStorageDict) {
    //             setIconDictionary(JSON.parse(localStorageDict));
    //         }
    //         setFinishedLoading(true);
    //     },
    //     []
    // )

    // useEffect(
    //     () => {
    //         localStorage.setItem("iconDictionary", JSON.stringify(iconDictionary));
    //     },
    //     [iconDictionary]
    // )

    return (
        <IconContext.Provider value={[fetchIcon]}>
            {/* {finishedLoading && props.children} */}
            {props.children}
        </IconContext.Provider>
    );
};
