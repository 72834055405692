import { useEffect, useRef } from 'react';
import { IconName, IconVariant } from '@decidalo-frontend/models';
import { useSvgData } from './useSvgData';

export interface SimpleIconProps {
    /**
     * The name of the icon to be used.
     */
    icon: IconName;
    /**
     * Defines the folder from which the icon is loaded.
     * @default is "thin"
     */
    variant?: IconVariant;
    /**
     * The width of the icon. Can be a number as pixels or one of the standard values.
     * Should be omitted if height is provided to retain the icon's ratio.
     * If no width and no height is given height will be set to 20 (medium).
     */
    width?:
        | number
        | 'xsmall'
        | 'small'
        | 'medium'
        | 'mediumlarge'
        | 'large'
        | 'xlarge'
        | 'xxlarge';
    /**
     * The height of the icon. Can be a number as pixels or one of the standard values.
     * Should be omitted if width is provided to retain the icon's ratio.
     * Default height is 20 (medium).
     */
    height?:
        | number
        | 'xsmall'
        | 'small'
        | 'medium'
        | 'mediumlarge'
        | 'large'
        | 'xlarge'
        | 'xxlarge';

    /**
     * Defines wheter icon should be displayed or not
     * @default is true
     */
    isDisplayed?: boolean;

    color?: string;
}

/**
 * Renders the icon as an svg-tag. The color of the icon can be changed.
 * E.g. if the icon is used as a button, the color-property of the button determines the color of the icon.
 *
 * Use either width or height to preserve the icon's ratios. If both are provided, the icon will rescale.
 */
export function SimpleIcon(props: Readonly<SimpleIconProps>) {
    let width: number | undefined;
    switch (props.width) {
        case 'xsmall':
            width = 12;
            break;
        case 'small':
            width = 15;
            break;
        case 'medium':
            width = 20;
            break;
        case 'mediumlarge':
            width = 24;
            break;
        case 'large':
            width = 30;
            break;
        case 'xlarge':
            width = 48;
            break;
        case 'xxlarge':
            width = 60;
            break;
        default:
            width = props.width;
            break;
    }
    let height: number | undefined;
    switch (props.height) {
        case 'xsmall':
            height = 12;
            break;
        case 'small':
            height = 15;
            break;
        case 'medium':
            height = 20;
            break;
        case 'mediumlarge':
            height = 24;
            break;
        case 'large':
            height = 30;
            break;
        case 'xlarge':
            height = 48;
            break;
        case 'xxlarge':
            height = 60;
            break;
        default:
            //This sets the default height to 20
            height = props.width || props.height ? props.height : 20;
            break;
    }
    const { variant = 'thin' } = { ...props };

    const ImportedIconRef = useRef<SVGSVGElement>(null);

    const { iconText } = useSvgData(props.icon, variant);

    useEffect(() => {
        if (ImportedIconRef?.current && iconText) {
            const svgElem = new DOMParser()
                .parseFromString(iconText, 'image/svg+xml')
                .getElementsByTagName('svg')[0];
            ImportedIconRef.current.setAttribute(
                'xmlns',
                svgElem.getAttribute('xmlns') ?? '',
            );
            ImportedIconRef.current.setAttribute(
                'viewBox',
                svgElem.getAttribute('viewBox') ?? '',
            );
            ImportedIconRef.current.innerHTML = svgElem.innerHTML;

            // Set fill property of path to currentColor to make it possible to recolor the icons.
            ImportedIconRef.current
                .getElementsByTagName('path')[0]
                .setAttribute('fill', 'currentColor');
        }
    }, [ImportedIconRef, iconText]);

    return (
        <svg
            role="img"
            ref={ImportedIconRef}
            height={height}
            width={width}
            viewBox="0 0 0 0"
            color={props.color}
            xmlns="http://www.w3.org/2000/svg"
            display={
                props.isDisplayed === false || !iconText ? 'none' : 'inline'
            } // Hide the icon on purpose or when the icon is not loaded yet
        />
    );
}
export default SimpleIcon;
