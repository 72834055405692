import { useDrop } from 'react-dnd';
import { Stack } from '@mui/material';
import { Marker, Row, WIDTH_OF_A_DAY } from '../timeline-scheduler';
import styles from '../timeline-scheduler.module.less';

export interface TimelineSchedulerCellProps {
    onDropRow: (row: Row, daysOffset: number) => void;
    onDropResize: (row: Row, startOrEnd: 'start' | 'end') => void;
    onDropMarker: (marker: Marker) => void;
}

export const TimelineSchedulerCell = (props: TimelineSchedulerCellProps) => {
    const [, drop] = useDrop(
        {
            accept: ['schedule-entry', 'resize', 'marker'],
            hover: (
                item:
                    | { row: Row; daysOffset: number }
                    | { row: Row; startOrEnd: 'start' | 'end' }
                    | { marker: Marker },
            ) => {
                if ('daysOffset' in item) {
                    props.onDropRow(item.row, item.daysOffset);
                } else if ('startOrEnd' in item) {
                    props.onDropResize(item.row, item.startOrEnd);
                } else {
                    props.onDropMarker(item.marker);
                }
            },
        },
        [props.onDropRow],
    );

    return (
        <Stack
            className={styles['grid-content-cell']}
            alignItems={'center'}
            justifyContent={'center'}
            width={WIDTH_OF_A_DAY}
            ref={drop}
        ></Stack>
    );
};
