import { AxiosResponseHeaders, RawAxiosResponseHeaders } from 'axios';
// circular dependency is due to  usage of exhaustive check in decidalo-frontend/models
// eslint-disable-next-line @nx/enforce-module-boundaries -- justification above
import {
    FileType,
    FileTypeExtension,
    MimeType,
} from '@decidalo-frontend/models';
import { RegexConstants } from '../../constants/regex-constants';
import { exhaustiveCheck } from '../../type-utilities/enum-coverage-check';

/**
 * Creates a safe time stamp string to be used in file names.
 * @returns {string} Unique string in the format YYYY-MM-ddThh-mm-ss - i.e. the colons are removed from the time parts.
 */
export const createSafeTimestampString = () => {
    const timestamp = new Date()
        .toISOString()
        .substring(0, 19) // cut off micro/milliseconds: "YYYY-MM-ddThh:mm:ss".length === 19
        .replace('/:/g', '-'); // replace ":" by some safe character

    return timestamp;
};

export const downloadFile = (data: Blob, fileName: string) => {
    const url = URL.createObjectURL(data);
    const anchorElementForDownload = document.createElement('a');
    anchorElementForDownload.href = url;
    anchorElementForDownload.download = fileName;
    anchorElementForDownload.click();
    URL.revokeObjectURL(url);
};

/**
 * Extracts the filename from the 'Content-Disposition' header.
 *
 * This function looks for the 'Content-Disposition' header in the provided headers object
 * and extracts the filename using a regular expression that matches both quoted and unquoted filenames.
 *
 * @param {RawAxiosResponseHeaders | AxiosResponseHeaders} headers - The response headers containing the 'Content-Disposition' field.
 * @returns {string} The extracted filename. Returns an empty string if the filename is not found.
 *
 * @example
 * // Example with quoted filename
 * const headers = { 'content-disposition': 'attachment; filename="example.pdf"' };
 * const filename = extractFileName(headers); // Returns 'example.pdf'
 *
 * @example
 * // Example with unquoted filename
 * const headers = { 'content-disposition': 'attachment; filename=example.pdf' };
 * const filename = extractFileName(headers); // Returns 'example.pdf'
 */
export const extractFileName = (
    headers: RawAxiosResponseHeaders | AxiosResponseHeaders,
): string => {
    const contentDisposition = headers['content-disposition'];

    let filename = '';
    if (contentDisposition) {
        // Regex to match both quoted and unquoted filenames
        const matches = contentDisposition.match(RegexConstants.FilenameRegex);
        if (matches && matches.length === 2) {
            filename = matches[1];
        }
    }
    return filename;
};

/**
 * Maps a file type from the `FileType` enum to its corresponding MIME type in the `MimeType` enum.
 *
 * This function takes a `FileType` value and returns the corresponding MIME type string defined
 * in the `MimeType` enum. If the provided file type does not have a corresponding MIME type,
 * the function returns `undefined`.
 *
 * @param {number} fileType - A value from the `FileType` enum representing the type of the file.
 * @returns {string | undefined} The MIME type string corresponding to the provided `FileType`,
 *          or `undefined` if the file type is not recognized.
 */

export function mapFileTypeToMimeType(fileType: FileType): MimeType {
    switch (fileType) {
        case FileType.PDF:
            return MimeType.PDF;
        case FileType.DOCX:
            return MimeType.DOCX;
        case FileType.PPTX:
            return MimeType.PPTX;
        case FileType.JPEG:
            return MimeType.JPEG;
        case FileType.PNG:
            return MimeType.PNG;
        default:
            throw exhaustiveCheck(fileType);
    }
}

export function mapFileTypeToFileTypeExtension(
    fileType: FileType,
): FileTypeExtension {
    switch (fileType) {
        case FileType.PDF:
            return FileTypeExtension.PDF;
        case FileType.DOCX:
            return FileTypeExtension.DOCX;
        case FileType.PPTX:
            return FileTypeExtension.PPTX;
        case FileType.JPEG:
            return FileTypeExtension.JPEG;
        case FileType.PNG:
            return FileTypeExtension.PNG;
        default:
            throw exhaustiveCheck(fileType);
    }
}

/**
 * Generates a comma-separated string of MIME types for the "accept" attribute of a file input element,
 * based on the provided file types.
 *
 * This function converts an array of file types into their corresponding MIME types and formats them
 * into a string suitable for the `accept` attribute of an `<input type="file">` element. If no file types
 * are provided or the array is empty, the function returns an empty string.
 *
 * @param {FileType[]} [acceptedFiles] - An optional array of file types to include in the MIME type string.
 *                                      If omitted or an empty array is provided, an empty string will be returned.
 *
 * @returns {string} A comma-separated string of MIME types. Each MIME type corresponds to a file type
 *                   from the provided array. If no valid file types are provided, returns an empty string.
 **/
export function generateAcceptString(acceptedFiles?: FileType[]): string {
    if (!acceptedFiles || acceptedFiles.length === 0) {
        return '';
    }

    // Map file types to MIME types and filter out undefined values
    const mimeTypes = acceptedFiles.map((fileType) =>
        mapFileTypeToMimeType(fileType),
    );

    // Join MIME types with commas to create the accept string
    return mimeTypes.join(',');
}

export function generateFileExtensionsString(
    acceptedFiles?: FileType[],
): string {
    if (!acceptedFiles || acceptedFiles.length === 0) {
        return '';
    }

    const fileExtensions = acceptedFiles.map((fileType) =>
        mapFileTypeToFileTypeExtension(fileType),
    );

    return fileExtensions.join(', ');
}
