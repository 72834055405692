import { MenuItem, MenuItemProps, styled } from '@mui/material';

interface AnimatedMenuItemProps extends MenuItemProps {
    /**
     * Defines if MenuItem should have an animation
     * @default true
     */
    animated?: boolean;
}

/*This is MUI Menu item that has extended props in order to be restyled with custom animation.*/

export const AnimatedMenuItem = styled(MenuItem, {
    shouldForwardProp: (props) => props !== 'animated',
})<AnimatedMenuItemProps>(({ animated = true }) => ({
    ...(animated && {
        backgroundColor: 'var(--whiteColor)',
        border: '2px solid var(--blueActiveColor)',
        borderRadius: '5px',
        animation: 'zoom-in-zoom-out 2s infinite',
        //zIndex: '10000000000', // quick-fix
        '@keyframes zoom-in-zoom-out': {
            '0%': {
                transform: 'scale(1, 1)',
            },

            '50%': {
                transform: 'scale(1.1, 1.1)',
            },

            '100%': {
                transform: 'scale(1, 1)',
            },
        },
    }),
}));

export default AnimatedMenuItem;
