import { PermissionScope, User } from '@decidalo-frontend/models';

const baseUser: Omit<
    User,
    'isSuperUser' | 'isSSOEnabled' | 'featurePermissions'
> = {
    userId: 1,
    displayName: 'John Doe',
    email: 'johndoe@dataassesment.com',
    countryCode: 'DE',
};

export const RestrictedUserMock: User = {
    ...baseUser,
    isSuperUser: false,
    isSSOEnabled: false,
    featurePermissions: {
        userManagement: false,
        userInvitation: false,
        profileView: PermissionScope.Own,
        profileEdit: PermissionScope.Own,
        profileCreation: false,
        skillMatrixView: false,
        projectEdit: PermissionScope.Own,
        resourceRequestView: PermissionScope.Own,
        templates: false,
        companiesAndIndustries: false,
        skillMatrixList: false,
        metamodel: false,
        trainingEdit: PermissionScope.Own,
        useCVParser: false,
        featurePreview: false,
        viewAnonymousCvs: PermissionScope.Own,
        createAnonymousCvs: PermissionScope.Own,
        createNonAnonymousCvs: PermissionScope.Own,
        editVisibleCvs: PermissionScope.Own,
        lockAndUnlockCvs: PermissionScope.Own,
        skillsAndCertificates: false,
        proposalView: false,
    },
};

export const AdministratorMock: User = {
    ...baseUser,
    isSuperUser: true,
    isSSOEnabled: false,
    featurePermissions: {
        userManagement: true,
        userInvitation: true,
        profileView: PermissionScope.All,
        profileEdit: PermissionScope.All,
        profileCreation: true,
        skillMatrixView: true,
        projectEdit: PermissionScope.All,
        resourceRequestView: PermissionScope.All,
        templates: true,
        companiesAndIndustries: true,
        skillMatrixList: true,
        metamodel: true,
        trainingEdit: PermissionScope.All,
        useCVParser: true,
        featurePreview: true,
        viewAnonymousCvs: PermissionScope.All,
        createAnonymousCvs: PermissionScope.All,
        createNonAnonymousCvs: PermissionScope.All,
        editVisibleCvs: PermissionScope.All,
        lockAndUnlockCvs: PermissionScope.All,
        skillsAndCertificates: true,
        proposalView: true,
    },
};
