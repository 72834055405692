import dayjs from 'dayjs';
import { ProjectPlan } from '@decidalo-frontend/features/powerpoint-project-plan/data-access/models';
import { downloadFile } from '@decidalo-frontend/helpers';

export function GeneratePowerpointPlan(projectPlan: ProjectPlan) {
    return fetch(
        `${process.env['NX_PUBLIC_PROJECTPLAN_BACKEND_URL']}/api/GeneratePowerpointPlan`,
        {
            method: 'POST',
            headers: {
                'Content-Type': 'blob',
            },
            body: JSON.stringify(projectPlan, (key, value) => {
                if (
                    key === 'startDate' ||
                    key === 'endDate' ||
                    key === 'date'
                ) {
                    const dateOnly = dayjs(new Date(value))
                        .utc(true)
                        .format('YYYY-MM-DD');
                    return dateOnly;
                } else {
                    return value;
                }
            }),
        },
    )
        .then(
            (response) => response.blob(),
            (e) => {
                console.log(e);
                throw e;
            },
        )
        .then((data) => {
            if (data) downloadFile(data, 'project-plan.pptx');
        })
        .catch((e) => console.error(e));
}
