import { useContext, useEffect, useState } from 'react';
import { IconName, IconVariant } from '@decidalo-frontend/models';
import { IconContext } from './IconContext';

export function useSvgData(icon: IconName, variant: IconVariant = 'thin') {
    const [svgData, setSvgData] = useState<string>();
    const [iconFetch] = useContext(IconContext);
    const [error, setError] = useState<string | null>(null);

    useEffect(
        () => {
            iconFetch(icon, variant, setSvgData, setError);
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps --  //Ignore linter in this case because we specifically don't want the svgData to be updated when the iconDictionary changes. We only want the svgData to change when the icon changes.
        [icon, variant],
    );

    return { iconText: svgData, error };
}
