import React from 'react';
import { Stack } from '@mui/material';
import { Marker, Row } from '../timeline-scheduler';
import { TimelineSchedulerCell } from './timeline-scheduler-cell';
import styles from '../timeline-scheduler.module.less';

export interface GridContentProps {
    numberOfRows: number;
    handleDropRow: (day: Date) => (row: Row, daysOffset: number) => void;
    handleDropResize: (
        day: Date,
    ) => (row: Row, startOrEnd: 'start' | 'end') => void;
    handleDropMarker: (day: Date) => (marker: Marker) => void;
    daysGroupedByMonth: Record<string, Date[]>;
}

export const GridContent = (props: GridContentProps) => {
    return (
        <Stack className={styles['grid-content']}>
            {Array.from({ length: props.numberOfRows }).map((_row, index) => (
                <Stack
                    direction={'row'}
                    gap={0}
                    className={styles['grid-content-row']}
                    key={index}
                >
                    {Object.entries(props.daysGroupedByMonth).map(
                        ([month, days]) => (
                            <Stack
                                key={month}
                                direction={'row'}
                                className={styles['grid-content-month-cells']}
                            >
                                {days.map((day) => (
                                    <TimelineSchedulerCell
                                        key={day.toISOString()}
                                        onDropRow={props.handleDropRow(day)}
                                        onDropResize={props.handleDropResize(
                                            day,
                                        )}
                                        onDropMarker={props.handleDropMarker(
                                            day,
                                        )}
                                    />
                                ))}
                            </Stack>
                        ),
                    )}
                </Stack>
            ))}
        </Stack>
    );
};

export const MemoizedGridContent = React.memo(GridContent);
