import { useEffect, useState } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { Stack, Typography } from '@mui/material';
import { WorkPackageScheduler } from '../work-package-scheduler/work-package-scheduler';

export function PowerpointProjectPlanContainer() {
    const [normalView, setNormalView] = useState<boolean>(true);
    useEffect(() => {
        const location = window.location;
        setNormalView(location.search !== '?iframe');
    }, []);

    return (
        <Stack
            direction={'row'}
            height={'calc(100vh - 16px)'}
            width={'calc(100vw - 16px)'}
        >
            <Stack gap={2} height={'100%'} width={'100%'}>
                {normalView && (
                    <Typography variant="h1" color={'var(--primaryColor)'}>
                        decídalo
                    </Typography>
                )}
                <DndProvider backend={HTML5Backend}>
                    <WorkPackageScheduler />
                </DndProvider>
            </Stack>
        </Stack>
    );
}

export default PowerpointProjectPlanContainer;
