import { ConnectDragSource } from 'react-dnd';
import { SimpleIcon } from '@decidalo-frontend/ui-elements/icons';

export interface ResizeGripProps {
    drag: ConnectDragSource;
}

export const ResizeGrip = ({ drag }: ResizeGripProps) => {
    return (
        <div
            style={{
                opacity: 0.5,
                cursor: 'e-resize',
                height: '100%',
                width: '12px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}
            ref={drag}
        >
            <SimpleIcon
                icon={'grip-dots-vertical'}
                color="white"
                height={10}
                variant="solid"
            />
        </div>
    );
};
