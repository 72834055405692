import React from 'react';
import { useDrag } from 'react-dnd';
import { Marker, WIDTH_OF_A_MARKER } from '../timeline-scheduler';

export interface MarkerEntryProps {
    marker: Marker;
    style: React.CSSProperties;
}

export const MarkerEntry = ({ marker, style }: MarkerEntryProps) => {
    const [{ isDragging }, drag, preview] = useDrag({
        type: 'marker',
        item: { marker },
        collect: (monitor) => ({
            isDragging: !!monitor.isDragging(),
        }),
    });

    return (
        <>
            <div
                style={{
                    ...style,
                    width: `${WIDTH_OF_A_MARKER}px`,
                    backgroundColor: 'var(--primaryColor)',
                    position: 'absolute',
                    cursor: 'e-resize',
                    pointerEvents: isDragging ? 'none' : 'auto',
                }}
                ref={drag}
            />
            <div ref={preview} style={{ display: 'none' }} />
        </>
    );
};
