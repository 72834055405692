import { ThemeProvider } from '@mui/material';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { PowerpointProjectPlanContainer } from '@decidalo-frontend/features/powerpoint-project-plan/container';
import { IconContextProvider } from '@decidalo-frontend/ui-elements/icons';
import { decidaloTheme } from '@decidalo-frontend/ui-themes';

//Do not change this values prior to discussion and agreement. They are set
//as agreed to be the best defaults that fit current state of our app.
const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            //This option means that when window is focused data would be re-fetched( if it is stale)
            refetchOnWindowFocus: false,
            //Number of milliseconds after which data is considered as stale, and then would be re-fetched when accessing it.
            // We assume this is fine especially in cases when multiple or two sibling components use same service method
            // to fetch data. This would mean that no two same backend api calls would ever be triggered within this time frame.
            staleTime: 2000,
        },
    },
});

export function App() {
    return (
        <QueryClientProvider client={queryClient}>
            <ThemeProvider theme={decidaloTheme}>
                <IconContextProvider>
                    <PowerpointProjectPlanContainer />
                </IconContextProvider>
            </ThemeProvider>
        </QueryClientProvider>
    );
}

export default App;
