import { useCallback, useMemo } from 'react';
import { Button, Stack } from '@mui/material';
import { useImmer } from 'use-immer';
import {
    ProjectPlan,
    WorkPackageBase,
} from '@decidalo-frontend/features/powerpoint-project-plan/data-access/models';
import { useGeneratePowerpointPlan } from '@decidalo-frontend/features/powerpoint-project-plan/data-access/service';
import { SimpleIcon } from '@decidalo-frontend/ui-elements/icons';
import TimelineScheduler, {
    Marker,
    Row,
    TimelineSchedulerProps,
} from '../timeline-scheduler/timeline-scheduler';
import { SchedulerHeader } from './work-package-components/schedule-header';

export function WorkPackageScheduler() {
    const [projectPlan, setProjectPlan] = useImmer<ProjectPlan>(() => {
        const newDate = new Date(Date.now());
        newDate.setHours(0, 0, 0, 0);
        return {
            name: 'Project plan',
            workPackages: [
                {
                    id: 1,
                    isPackageGroup: false,
                    resources: '',
                    name: '',
                    startDate: newDate,
                    endDate: new Date(
                        newDate.getTime() + 28 * 24 * 60 * 60 * 1000,
                    ),
                },
            ],
            milestones: [],
        };
    });

    const powerpointPlanGenerator = useGeneratePowerpointPlan();

    const addNewWorkpackage = useCallback(() => {
        const newDate = new Date(Date.now());
        newDate.setHours(0, 0, 0, 0);
        setProjectPlan((prevPlan) => {
            const newWP: WorkPackageBase = {
                id:
                    prevPlan.workPackages.reduce(
                        (prev, curr) => Math.max(prev, curr.id),
                        0,
                    ) + 1,
                isPackageGroup: false,
                resources: '',
                name: ``,
                startDate:
                    prevPlan.workPackages.length > 0
                        ? prevPlan.workPackages[
                              prevPlan.workPackages.length - 1
                          ].startDate
                        : newDate,
                endDate:
                    prevPlan.workPackages.length > 0
                        ? prevPlan.workPackages[
                              prevPlan.workPackages.length - 1
                          ].endDate
                        : new Date(
                              newDate.getTime() + 28 * 24 * 60 * 60 * 1000,
                          ),
            };
            prevPlan.workPackages.push(newWP);
        });
    }, [setProjectPlan]);

    const addNewMilestone = useCallback(() => {
        setProjectPlan((prevPlan) => {
            const newDate = new Date(
                prevPlan.workPackages.length > 0
                    ? prevPlan.workPackages[
                          prevPlan.workPackages.length - 1
                      ].endDate.getTime()
                    : Date.now(),
            );
            newDate.setHours(0, 0, 0, 0);
            const newMilestone = {
                id:
                    prevPlan.milestones.reduce(
                        (prev, curr) => Math.max(prev, curr.id),
                        0,
                    ) + 1,
                date: newDate,
                name: ``,
            };
            prevPlan.milestones.push(newMilestone);
        });
    }, [setProjectPlan]);

    const timelineProps: Omit<TimelineSchedulerProps, 'rows' | 'markers'> =
        useMemo(() => {
            return {
                updateMarker: (marker: Marker) => {
                    setProjectPlan((prevPlan) => {
                        const milestone = prevPlan.milestones.find(
                            (m) => m.id === marker.id,
                        );
                        if (milestone) {
                            milestone.date = marker.date;
                        }
                    });
                },
                updateRow: (row: Row) => {
                    setProjectPlan((prevPlan) => {
                        const wp = prevPlan.workPackages.find(
                            (wp) => wp.id === row.id,
                        );
                        if (wp) {
                            wp.startDate = row.startDate;
                            wp.endDate = row.endDate;
                            wp.name = row.name;
                        }
                    });
                },
                deleteRow: (row: Row) => {
                    setProjectPlan((prevPlan) => {
                        prevPlan.workPackages = prevPlan.workPackages.filter(
                            (wp) => wp.id !== row.id,
                        );
                    });
                },
                deleteMarker: (marker: Marker) => {
                    setProjectPlan((prevPlan) => {
                        prevPlan.milestones = prevPlan.milestones.filter(
                            (m) => m.id !== marker.id,
                        );
                    });
                },
                addNewRow: addNewWorkpackage,
            };
        }, [addNewWorkpackage, setProjectPlan]);

    const rows = useMemo(
        () =>
            projectPlan.workPackages.map((wp) => ({
                id: wp.id,
                name: wp.name,
                startDate: wp.startDate,
                endDate: wp.endDate,
            })),
        [projectPlan.workPackages],
    );

    const markers = useMemo(() => {
        return projectPlan.milestones.map((milestone) => ({
            id: milestone.id,
            date: milestone.date,
            name: milestone.name,
        }));
    }, [projectPlan.milestones]);

    return (
        <Stack gap={1} minHeight={0}>
            <SchedulerHeader
                projectPlan={projectPlan}
                setProjectPlan={setProjectPlan}
            />
            <TimelineScheduler
                rows={rows}
                markers={markers}
                {...timelineProps}
            />
            <Stack direction={'row'} gap={2}>
                <Button variant="text" onClick={addNewWorkpackage}>
                    <Stack direction={'row'} gap={1} alignItems="center">
                        <SimpleIcon icon={'plus'} />
                        Add row
                    </Stack>
                </Button>
                <Button variant="text" onClick={addNewMilestone}>
                    <Stack direction={'row'} gap={1} alignItems="center">
                        <SimpleIcon icon={'plus'} />
                        Add milestone
                    </Stack>
                </Button>
            </Stack>

            <Button
                onClick={() => powerpointPlanGenerator.execute(projectPlan)}
                disabled={powerpointPlanGenerator.isPending}
            >
                Download
                {powerpointPlanGenerator.isPending && 'ing...'}
            </Button>
        </Stack>
    );
}
