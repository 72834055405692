import { useEffect, useState } from 'react';
import { Alert, Button, Snackbar, Stack, Typography } from '@mui/material';
import { ProjectPlan } from '@decidalo-frontend/features/powerpoint-project-plan/data-access/models';
import {
    DeleteProjectPlan,
    GetProjectPlan,
    UpdateProjectPlan,
    useCreateProjectPlanLink,
} from '@decidalo-frontend/features/powerpoint-project-plan/data-access/service';

export interface SchedulerHeaderProps {
    projectPlan: ProjectPlan;
    setProjectPlan: (projectPlan: ProjectPlan) => void;
}

export const SchedulerHeader = ({
    projectPlan,
    setProjectPlan,
}: SchedulerHeaderProps) => {
    const [guid, setGuid] = useState<string | null>(null);

    const [showCopyLinkButton, setShowCopyLinkButton] = useState(false);

    const [normalView, setNormalView] = useState<boolean>(true);

    useEffect(() => {
        const location = window.location;
        const splitPath = location.pathname.split('/');
        setGuid(splitPath.length > 1 ? splitPath[splitPath.length - 1] : null);

        setNormalView(location.search !== '?iframe');
    }, []);

    const [toasterMessage, setToasterMessage] = useState<string | null>(null);

    const [toasterSeverity, setToasterSeverity] = useState<'success' | 'error'>(
        'success',
    );

    const projectPlanLinkCreator = useCreateProjectPlanLink();

    useEffect(() => {
        if (guid) {
            GetProjectPlan(guid)
                .then((projectPlan) => {
                    setProjectPlan(projectPlan);
                })
                .catch((e) => {
                    setToasterMessage(
                        'There is no project plan under this URL, or it has been deleted.',
                    );
                    setToasterSeverity('error');
                    setGuid(null);
                    window.history.pushState(
                        {},
                        '',
                        `${window.location.origin}`,
                    );
                });
        }
    }, [guid, setProjectPlan]);

    return (
        <Stack
            gap={1}
            direction={'row'}
            sx={{
                justifyContent: 'space-between',
                alignItems: 'center',
            }}
        >
            <Typography variant="h1">
                {normalView ? 'Project plan' : ''}
            </Typography>
            <Stack direction={'row'} gap={1}>
                <Button
                    onClick={() => {
                        (guid
                            ? UpdateProjectPlan(projectPlan, guid)
                            : projectPlanLinkCreator.execute(projectPlan)
                        ).then((r) => {
                            window.history.pushState(
                                {},
                                '',
                                `${window.location.origin}/${r.slice(1, -1)}${normalView ? '' : '?iframe'}`,
                            );
                            setToasterMessage(
                                guid
                                    ? 'Project plan saved.'
                                    : 'Project plan saved. Bookmark the page or copy the url to get back to this project plan.',
                            );
                            setToasterSeverity('success');
                            setShowCopyLinkButton(!guid);

                            setGuid(r.slice(1, -1));
                        });
                    }}
                    disabled={projectPlanLinkCreator.isPending}
                >
                    {guid ? 'Save' : 'Create link | save'}
                </Button>
                {guid && normalView && (
                    <Button
                        onClick={() => {
                            DeleteProjectPlan(guid);
                            setProjectPlan({
                                name: 'Project Plan',
                                workPackages: [
                                    {
                                        id: 1,
                                        isPackageGroup: false,
                                        resources: '',
                                        name: '',
                                        startDate: new Date(Date.now()),
                                        endDate: new Date(
                                            Date.now() +
                                                28 * 24 * 60 * 60 * 1000,
                                        ),
                                    },
                                ],
                                milestones: [],
                            });
                            setToasterSeverity('success');
                            setToasterMessage('Project plan deleted.');
                            setGuid(null);
                            window.history.pushState(
                                {},
                                '',
                                `${window.location.origin}`,
                            );
                        }}
                        variant="secondaryAction"
                    >
                        Delete link
                    </Button>
                )}
            </Stack>
            <Snackbar
                onClose={() => {
                    setToasterMessage(null);
                    setShowCopyLinkButton(false);
                }}
                open={toasterMessage !== null}
            >
                <Alert severity={toasterSeverity}>
                    {toasterMessage}
                    {showCopyLinkButton && (
                        <Button
                            variant="text"
                            onClick={() =>
                                navigator.clipboard.writeText(
                                    window.location.toString(),
                                )
                            }
                        >
                            Copy link
                        </Button>
                    )}
                </Alert>
            </Snackbar>
        </Stack>
    );
};
