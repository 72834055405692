import React, { useCallback, useEffect, useMemo } from 'react';
import {
    Stack,
    Typography,
    Button,
    TextField,
    Input,
    IconButton,
} from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs, { Dayjs } from 'dayjs';
import { SimpleIcon } from '@decidalo-frontend/ui-elements/icons';
import { MemoizedGridContent } from './timeline-scheduler-components/grid-content';
import { MarkerEntry } from './timeline-scheduler-components/marker-entry';
import { ScheduleEntry } from './timeline-scheduler-components/schedule-entry';
import styles from './timeline-scheduler.module.less';

export const WIDTH_OF_A_DAY = 7;
export const HEIGHT_OF_A_ROW = 56;
export const HEIGHT_OF_THE_HEADER = 32;
export const HEIGHT_OF_A_SCHEDULE_ENTRY = 20;
export const WIDTH_OF_A_MARKER = 3;
export const WIDTH_OF_NAME_CELL = 300;

export const MILLIS_IN_A_DAY = 24 * 60 * 60 * 1000;

export interface Row {
    id: number;
    name: string;
    startDate: Date;
    endDate: Date;
}

export interface Marker {
    id: number;
    name: string;
    date: Date;
}

export interface TimelineSchedulerProps {
    rows: Row[];
    updateRow: (row: Row) => void;
    markers: Marker[];
    updateMarker: (marker: Marker) => void;
    addNewRow: () => void;
    deleteRow: (row: Row) => void;
    deleteMarker: (marker: Marker) => void;
}

export function getDaysBetween(start: Dayjs, end: Dayjs) {
    const range = [];
    let current = start;
    while (!current.isAfter(end)) {
        range.push(current.toDate());
        current = current.add(1, 'days');
    }
    return range;
}

function calculateDates(startDate: Date, durationInMonths: number): Date[] {
    return getDaysBetween(
        dayjs(startDate),
        dayjs(startDate).add(durationInMonths, 'M'),
    );
}

export function TimelineScheduler({
    rows,
    updateRow,
    markers,
    updateMarker,
    addNewRow,
    deleteRow,
    deleteMarker,
}: Readonly<TimelineSchedulerProps>) {
    const [startDate, setStartDate] = React.useState<Date>(() => {
        const date = new Date(Date.now());
        date.setUTCDate(1); // ?!
        date.setHours(0, 0, 0, 0);
        return date;
    });
    const [calendarMonths, setCalendarMonths] = React.useState<number>(6);

    const [days, setDays] = React.useState<Date[]>(() =>
        calculateDates(startDate, calendarMonths),
    );

    useEffect(() => {
        if (
            startDate &&
            startDate instanceof Date &&
            !isNaN(startDate.getTime())
        ) {
            setDays(calculateDates(startDate, calendarMonths));
        }
    }, [startDate, calendarMonths]);

    const daysGroupedByMonth = useMemo(
        () =>
            days.reduce<Record<string, Date[]>>((acc, day) => {
                const monthYear = `${day.toLocaleDateString('default', { year: '2-digit', month: 'short' })}`;
                if (!acc[monthYear]) {
                    acc[monthYear] = [];
                }
                acc[monthYear].push(day);
                return acc;
            }, {}),
        [days],
    );

    const handleDropRow = useCallback(
        (day: Date) => (row: Row, daysOffset: number) => {
            const potentialNewEndDate = new Date(
                day.getTime() -
                    daysOffset * MILLIS_IN_A_DAY +
                    (row.endDate.getTime() - row.startDate.getTime()),
            );
            if (potentialNewEndDate <= days[days.length - 1]) {
                row.endDate = potentialNewEndDate;
                row.startDate = new Date(
                    day.getTime() - daysOffset * MILLIS_IN_A_DAY,
                );
                updateRow(row);
            }
        },
        [days, updateRow],
    );

    const handleDropResize = useCallback(
        (day: Date) => (row: Row, startOrEnd: 'start' | 'end') => {
            if (startOrEnd === 'start') {
                const dropDate = new Date(day.getTime());
                const maxDate = new Date(
                    row.endDate.getTime() - MILLIS_IN_A_DAY,
                );
                row.startDate = dropDate > maxDate ? maxDate : dropDate;
            } else {
                const dropDate = new Date(day.getTime());
                const minDate = new Date(
                    row.startDate.getTime() + MILLIS_IN_A_DAY,
                );
                row.endDate = dropDate < minDate ? minDate : dropDate;
            }
            updateRow(row);
        },
        [updateRow],
    );

    const handleDropMarker = useCallback(
        (day: Date) => (marker: Marker) => {
            marker.date = new Date(day.getTime());
            updateMarker(marker);
        },
        [updateMarker],
    );

    const numberOfRows = useMemo(() => rows.length, [rows]);

    return (
        <Stack gap={1} width={'100%'} maxHeight={'100%'} minHeight={0}>
            <Stack
                direction={'row'}
                gap={1}
                className={styles['grid-content-tools']}
                width={'fit-content'}
                alignSelf={'end'}
                alignItems={'center'}
            >
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                        views={['month', 'year']}
                        label={'Start date'}
                        onChange={(date) => {
                            if (date) setStartDate(new Date(date));
                        }}
                        renderInput={(props) => <TextField {...props} />}
                        value={startDate}
                    />
                </LocalizationProvider>
                <Button
                    variant={'secondaryAction'}
                    onClick={() => setCalendarMonths((prev) => prev + 1)}
                >
                    Add month
                </Button>
            </Stack>

            <Stack spacing={0} className={styles['grid-content-container']}>
                <Stack className={styles['grid-content-header-days-container']}>
                    <Stack
                        direction={'row'}
                        gap={0}
                        className={styles['grid-content-header']}
                    >
                        <Stack
                            className={styles['grid-content-header-header']}
                            justifyContent={'center'}
                            height={'100%'}
                            flexShrink={0}
                        >
                            <Typography
                                variant="body2"
                                color={'var(--greysGrey500)'}
                            >
                                Activity
                            </Typography>
                        </Stack>
                        {Object.entries(daysGroupedByMonth).map(
                            ([month, days]) => (
                                <Stack
                                    key={month}
                                    alignItems={'center'}
                                    className={
                                        styles[
                                            'grid-content-header-month-container'
                                        ]
                                    }
                                    width={days.length * WIDTH_OF_A_DAY}
                                >
                                    <Stack
                                        className={
                                            styles['grid-content-header-month']
                                        }
                                        alignItems={'center'}
                                        justifyContent={'center'}
                                        height={'100%'}
                                    >
                                        <Typography
                                            variant="body2"
                                            color={'var(--greysGrey500)'}
                                        >
                                            {month}
                                        </Typography>
                                    </Stack>
                                    <Stack
                                        direction={'row'}
                                        className={
                                            styles['grid-content-header-days']
                                        }
                                    >
                                        {days.map((day) => (
                                            <Stack
                                                key={day.toISOString()}
                                                width={WIDTH_OF_A_DAY}
                                            ></Stack>
                                        ))}
                                    </Stack>
                                </Stack>
                            ),
                        )}
                    </Stack>
                    <Stack direction={'row'} gap={0}>
                        <Stack
                            direction={'column'}
                            gap={0}
                            position={'sticky'}
                            left={0}
                            zIndex={1}
                        >
                            {rows.map((row, index) => (
                                <Stack
                                    key={row.id}
                                    className={
                                        styles['grid-content-row-header']
                                    }
                                    alignItems={'center'}
                                    justifyContent={'space-between'}
                                    direction={'row'}
                                >
                                    <Input
                                        value={row.name}
                                        onChange={(e) => {
                                            row.name = e.target.value;
                                            updateRow(row);
                                        }}
                                        style={{
                                            color: 'inherit',
                                            backgroundColor: 'inherit',
                                            border: 'none',
                                            outline: 'none',
                                        }}
                                        disableUnderline={true}
                                        placeholder="Enter Activity..."
                                        onKeyDown={(e) => {
                                            if (e.key === 'Enter') {
                                                if (e.shiftKey) {
                                                    // Focus prev row
                                                    const rowElement =
                                                        findParentWithClass(
                                                            e.target as HTMLElement,
                                                            styles[
                                                                'grid-content-row-header'
                                                            ],
                                                        );
                                                    const prevRow =
                                                        rowElement?.previousElementSibling;
                                                    if (prevRow) {
                                                        prevRow
                                                            .querySelector(
                                                                'input',
                                                            )
                                                            ?.focus();
                                                    }
                                                } else {
                                                    if (
                                                        index ===
                                                        rows.length - 1
                                                    ) {
                                                        // Add new row
                                                        addNewRow();
                                                    }
                                                    setTimeout(() => {
                                                        // Focus next row
                                                        const rowElement =
                                                            findParentWithClass(
                                                                e.target as HTMLElement,
                                                                styles[
                                                                    'grid-content-row-header'
                                                                ],
                                                            );
                                                        const nextRow =
                                                            rowElement?.nextElementSibling;
                                                        if (nextRow) {
                                                            nextRow
                                                                .querySelector(
                                                                    'input',
                                                                )
                                                                ?.focus();
                                                        }
                                                    }, 0);
                                                }
                                            }
                                        }}
                                    />
                                    <IconButton
                                        onClick={() => {
                                            deleteRow(row);
                                        }}
                                    >
                                        <SimpleIcon icon={'trash'} />
                                    </IconButton>
                                </Stack>
                            ))}
                        </Stack>
                        <MemoizedGridContent
                            numberOfRows={numberOfRows}
                            handleDropRow={handleDropRow}
                            handleDropResize={handleDropResize}
                            handleDropMarker={handleDropMarker}
                            daysGroupedByMonth={daysGroupedByMonth}
                        />
                    </Stack>
                </Stack>
                {rows.map((row, rowIndex) => {
                    return (
                        <ScheduleEntry
                            key={row.id}
                            row={row}
                            initialDay={days[0]}
                            style={{
                                width: WIDTH_OF_A_DAY,
                                top:
                                    rowIndex * (HEIGHT_OF_A_ROW + 1) + // row height + border
                                    (HEIGHT_OF_A_ROW -
                                        HEIGHT_OF_A_SCHEDULE_ENTRY) /
                                        2 + // "padding" of the content inside the row
                                    HEIGHT_OF_THE_HEADER, // header height,
                            }}
                            days={days}
                        />
                    );
                })}
                {markers.map((marker) => {
                    const markerDay = marker.date;
                    const offSet =
                        (markerDay.getTime() - days[0].getTime()) /
                        MILLIS_IN_A_DAY;
                    return (
                        <MarkerEntry
                            key={marker.id}
                            marker={marker}
                            style={{
                                left:
                                    offSet * WIDTH_OF_A_DAY +
                                    WIDTH_OF_NAME_CELL + // row header
                                    (WIDTH_OF_A_DAY - WIDTH_OF_A_MARKER), // place marker at the end of a day
                                top: HEIGHT_OF_THE_HEADER,
                                height: `${
                                    HEIGHT_OF_A_ROW * rows.length + // height of all rows
                                    rows.length - // borders between rows
                                    2 // a bit of a gap
                                }px`,
                            }}
                        />
                    );
                })}
            </Stack>
        </Stack>
    );
}

const findParentWithClass = (element: HTMLElement, className: string) => {
    let parent: HTMLElement | null = element;
    while (parent && !parent.classList.contains(className)) {
        parent = parent.parentElement;
    }
    return parent;
};

export default TimelineScheduler;
