import React from 'react';
import { useDrag } from 'react-dnd';
import { Box, Typography } from '@mui/material';
import clsx from 'clsx';
import dayjs from 'dayjs';
import {
    MILLIS_IN_A_DAY,
    Row,
    WIDTH_OF_A_DAY,
    WIDTH_OF_NAME_CELL,
} from '../timeline-scheduler';
import { ResizeGrip } from './resize-grip';
import styles from '../timeline-scheduler.module.less';

export interface ScheduleEntryProps {
    row: Row;
    initialDay: Date;
    style: React.CSSProperties;
    days: Date[];
}

export const ScheduleEntry = ({
    row,
    initialDay,
    style,
    days,
}: ScheduleEntryProps) => {
    const startDate = row.startDate;
    const endDate = row.endDate;

    const [{ isDragging }, drag, preview] = useDrag({
        type: 'schedule-entry',
        item: (e) => {
            const position = e.getInitialClientOffset();
            const sourceOffset = e.getSourceClientOffset();
            let relativeXPosition = 0;
            if (position && sourceOffset) {
                relativeXPosition = position.x - sourceOffset.x;
            }
            const daysOffset = Math.round(relativeXPosition / WIDTH_OF_A_DAY);
            return { row, daysOffset };
        },
        collect: (monitor) => ({
            isDragging: !!monitor.isDragging(),
        }),
    });

    const [{ isDraggingStart }, dragStart, previewStart] = useDrag({
        type: 'resize',
        item: { row, startOrEnd: 'start' },
        collect: (monitor) => ({
            isDraggingStart: !!monitor.isDragging(),
        }),
    });
    const [{ isDraggingEnd }, dragEnd, previewEnd] = useDrag({
        type: 'resize',
        item: { row, startOrEnd: 'end' },
        collect: (monitor) => ({
            isDraggingEnd: !!monitor.isDragging(),
        }),
    });

    const isAnyDragging = isDragging || isDraggingStart || isDraggingEnd;

    if (startDate <= endDate) {
        const length =
            (endDate.getTime() - startDate.getTime() + MILLIS_IN_A_DAY) /
            MILLIS_IN_A_DAY;
        const offSet =
            (startDate.getTime() - initialDay.getTime()) / MILLIS_IN_A_DAY;

        const width = length * WIDTH_OF_A_DAY; // width of the days
        return (
            <>
                <Box
                    className={clsx(styles['grid-row-content'], {
                        [styles['dragging']]: isAnyDragging,
                    })}
                    style={{
                        ...style,
                        width: `${width}px`,
                        left: offSet * WIDTH_OF_A_DAY + WIDTH_OF_NAME_CELL, // row header
                        pointerEvents: isAnyDragging ? 'none' : 'auto',
                        cursor: 'pointer',
                    }}
                    ref={drag}
                >
                    <ResizeGrip drag={dragStart} />
                    <ResizeGrip drag={dragEnd} />
                    <div
                        className={styles['hover-content']}
                        style={{ zIndex: 2 }}
                    >
                        <Typography
                            variant="body2"
                            style={{
                                position: 'absolute',
                                left: -67,
                                top: -9,
                                color: 'var(--greysGrey900)',
                                whiteSpace: 'nowrap',
                                fontSize: '11px',
                            }}
                        >
                            {row.startDate.toLocaleDateString(undefined, {
                                month: 'short',
                                day: 'numeric',
                                year: 'numeric',
                            })}
                        </Typography>
                        <Typography
                            variant="body2"
                            style={{
                                position: 'absolute',
                                left: width + 4,
                                top: -9,
                                color: 'var(--greysGrey900)',
                                whiteSpace: 'nowrap',
                                fontSize: '11px',
                            }}
                        >
                            {row.endDate.toLocaleDateString(undefined, {
                                month: 'short',
                                day: 'numeric',
                                year: 'numeric',
                            })}
                        </Typography>
                        <Typography
                            variant="body2"
                            style={{
                                position: 'absolute',
                                left: width / 2 - 15,
                                top: -9,
                                color: 'var(--basicWhite)',
                                whiteSpace: 'nowrap',
                                fontSize: '11px',
                            }}
                        >
                            {dayjs(row.endDate).diff(row.startDate, 'day') + 1}
                            {' days'}
                        </Typography>
                    </div>
                </Box>
                <div
                    ref={(elem) => previewEnd(previewStart(preview(elem)))}
                    style={{ display: 'none' }}
                />
            </>
        );
    }
    return null;
};
