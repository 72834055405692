import dayjs from 'dayjs';
import { ProjectPlan } from '@decidalo-frontend/features/powerpoint-project-plan/data-access/models';

const baseUrl = process.env['NX_PUBLIC_PROJECTPLAN_BACKEND_URL'];
const apiName = 'api/ProjectPlanData';

export function GetProjectPlan(guid: string): Promise<ProjectPlan> {
    return fetch(`${baseUrl}/${apiName}?id=${guid}`, {
        method: 'GET',
    })
        .then(
            (response) => {
                return response.text().then((x) => {
                    const obj = JSON.parse(x, (key, value) => {
                        if (
                            key === 'startDate' ||
                            key === 'endDate' ||
                            key === 'date'
                        ) {
                            return new Date(value);
                        } else {
                            return value;
                        }
                    });
                    if (obj) {
                        return obj;
                    } else {
                        throw new Error('No project plan found');
                    }
                });
            },
            (e) => {
                console.log(e);
                throw e;
            },
        )
        .catch((e) => {
            console.error(e);
            throw e;
        });
}

export function DeleteProjectPlan(guid: string) {
    return fetch(`${baseUrl}/${apiName}?id=${guid}`, {
        method: 'DELETE',
    })
        .then(
            (response) => response,
            (e) => {
                console.log(e);
                throw e;
            },
        )
        .catch((e) => {
            console.error(e);
            throw e;
        });
}

export function AddProjectPlanLink(projectPlan: ProjectPlan) {
    return fetch(`${baseUrl}/${apiName}`, {
        method: 'POST',
        body: JSON.stringify(projectPlan, (key, value) => {
            if (key === 'startDate' || key === 'endDate' || key === 'date') {
                const dateOnly = dayjs(new Date(value))
                    .utc(true)
                    .format('YYYY-MM-DD');
                return dateOnly;
            } else {
                return value;
            }
        }),
    })
        .then(
            (response) => response.text().then((x) => x),
            (e) => {
                console.log(e);
                throw e;
            },
        )
        .catch((e) => {
            console.error(e);
            throw e;
        });
}
export function UpdateProjectPlan(projectPlan: ProjectPlan, token: string) {
    return fetch(`${baseUrl}/${apiName}/${token}`, {
        method: 'POST',
        body: JSON.stringify(projectPlan, (key, value) => {
            if (key === 'startDate' || key === 'endDate' || key === 'date') {
                const dateOnly = dayjs(new Date(value))
                    .utc(true)
                    .format('YYYY-MM-DD');
                return dateOnly;
            } else {
                return value;
            }
        }),
    })
        .then(
            (response) => response.text().then((x) => x),
            (e) => {
                console.log(e);
                throw e;
            },
        )
        .catch((e) => {
            console.error(e);
            throw e;
        });
}
